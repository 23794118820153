import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hiji-inquiry-answer-thanks',
  templateUrl: './inquiry-answer-thanks.component.html',
  styleUrls: ['./inquiry-answer-thanks.component.scss'],
})
export class InquiryAnswerThanksComponent implements OnInit {
  window: Window;
  constructor() {
    this.window = window;
  }

  ngOnInit(): void {}
}
